import './About.css';
import bhpic from '../images/double-bh.png';
import { Fade } from 'react-awesome-reveal';

function AboutBRB(props) {
  return (
    <div className='about-wrapper' id='about'>
      <div className='about'>
        <Fade direction='left'>
          <div className='about-desc'>

            <h1 className='about-title'>About the Black-Hole Recreation Board</h1>
            

              {/* <Fade direction="right">
                <img src={bhpic} className='profile-pic-mobile' alt="Profile"/>
              </Fade> */}
            
            <p>
            The Blackhole Recreation Board (BRB) is an agency of the Department for Distant Cosmic Happenings that maintains recreational and tourist facilities around our galaxy’s numerous blackholes. We want to make sure that all our guests have loads of fun while also staying safe. That’s right, BRB wants you to tell your family and friends that you will “Be right back!” and actually mean it. Please read our guide and travel advisory below so that you can have a memorable time (and actually have the opportunity to remember it!)
            </p>
          </div>
        </Fade>

        {/* <div className='profile-pic-wrapper'>
          <Fade direction='right'>
            <img src={bhpic} className='profile-pic' alt="Profile"/>
          </Fade>
        </div> */}
        
      </div>
    </div>
  );
}

export default AboutBRB;