// import React from "react";
import './Experience.css';
import ExperienceCard from '../components/ExperienceCard';
import supernova from '../images/supernova.jpeg';
import ngc1128 from '../images/ngc1128wide.jpeg';
import pic3c75 from '../images/3c75xray.jpeg';

function Experience(props) {
  return (
    <div id='experience' className='experience-wrapper'>
      <div className='experience'>

        <h1 className='experience-title'>A History of 3C 75</h1>

        <ExperienceCard
          title='A Long Time Ago: Formation'
          date=''
          image={supernova}
          img_desc="supernova"
          desc='Despite our progress and galactic conquest in the last few millenia, the formation of
          supermassive black holes are still a subject of debate among researchers at the Imperial Academy
          for Astrophysical Phenomena. One of the theories is that these supermassive black holes originated
          from supernovae and would accrete material up until now to grow to their current size. It has also
          been proposed that these supernovae might have come from first-generation stars, which would allow
          the initial black holes to be much bigger.'
        />

        <ExperienceCard
          title='Also a Long Time Ago: Meeting of two Galaxies'
          date=''
          desc='The two black holes forming 3C 75 are thought to have been the centers of their own respective
          galaxies. These two galaxies, which were apart, have at some point approched and met one another.
          What we are seeing right now are the two galaxies undergoing a merging process, hence the
          dumbbell shape of the resulting galaxy, NGC 1128. How romantic!'
        />

        <ExperienceCard
          title='October 8, 1886: First spotted by humans'
          date=''
          image={ngc1128}
          img_desc="ngc1128"
          desc="Did you know that 3C 75 is actually visible in the night sky from Earth (with the help of a telescope)?
          That's how a certain astronomer by the name of Lewis Swift. Though he failed to identify 3C 75 as a binary black
          hole system, and also got their positions a smidge wrong, he was still the first human to identify some kind of object
          at that location. (Latest measurements place 3C 75 at about RA 02:57:41.58s
          and Dec +06:01:28.81 from Earth - there have been calls to move to a more universal system for locating astronomical
          objects, but our astronomers are lazy and stuck with one pegged to some random planet.)"
        />

        <ExperienceCard
          title='1985: We figured out what it is!'
          date=''
          // image={collegepic}
          // img_desc="high school"
          desc="Nearly a hundred years later in 1985, scientists figured out that there was a binary
          blackhole system at the center of the galaxy. They determined this by comparing the data they
          had captured on their radio telescopes with simulations of objects that might produce such signals.
          They also figured that in order for 3C 75 to produce its famous “sprinkler” jets, the blackholes
          would have to move at speeds of around 10 000 ms-1 and have gas clouds more than 100 000°C!"
        />

        <ExperienceCard
          title='April 6, 2006: The picture that put 3C 75 on the map'
          date=''
          image={pic3c75}
          img_desc="3c75"
          desc="This composite image remains the most famous one of 3C 75. It is X-ray data in blue overlaid with
          radio data in pink. The blue showcases the intracluster medium (ICM) surrounding 3C 75, and the pink marks the
          jets emitted by the two supermassive black holes. It is theorized that the jets' peculiar shape are a
          consequence of its interactions with the ICM!"
        />

        <ExperienceCard
          title='3242: First humans at 3C 75'
          date=''
          // image={collegepic}
          // img_desc="high school"
          desc="In 3242, the Empire sent the first humans to the galaxy surrounding 3C 75, giving us
          unprecedented insight into the binary black holes. We then invaded and added the NGC 1128 galaxy,
          bringing civilization to this remote and barbarian part of the Universe. We officially annexed
          NGC 1128 in 3297, and it has been a source of cheap labour ever since. In 3342, in commemoration
          of the 100th anniversary of the first mission to NGC 1128, the BRB set the park boundaries of
          3C 75 Reserve and opened the doors of the Sharon Grand Observatory. Guests can now come to view
          its magnificent jets up close! "
        />

      </div>
    </div>
  )
}

export default Experience;