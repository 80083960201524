import './About.css';
import bhpic from '../images/double-bh.png';
import { Fade } from 'react-awesome-reveal';

function About3C75(props) {
  return (
    <div className='about-wrapper'>
      <div className='about'>
        <Fade direction='left'>
          <div className='about-desc'>

            <h1 className='about-title'>About 3C 75</h1>
            

              <Fade direction="right">
                <img src={bhpic} className='profile-pic-mobile' alt="Profile"/>
              </Fade>
            
            <p>
              Welcome to 3C 75, the biggest light show in the Universe!
            </p>
            <p>
            3C 75 is a binary black hole system located at the center of the dumbbell-shaped galaxy NGC 1128
            in Sector 42 beyond the Galactic Empire. Discovered a few millenia ago when humans were still mostly
            confined to Planet Earth, it has been an object of fascination for astronomers and laypeople alike.
            The two black holes, A400-42 and A400-43, that we at the BRB affectionately call Sprinkles Jr. and Sprinkles,
            are locked in a dance with each other as they emit spectacular looking radio jets. It is truly a sight to
            behold.
            
            <p>
            They spin around each other at 1200 kms-1, causing the jets that come out from the top and bottom of the
            black holes to sweep back the same way water jets seem to arc as they are shot out of the moving arms of
            a water sprinkler. The bending of the jets are also caused by their interactions with the intracluster
            </p>

            <p>
            Sprinkles and Sprinkles Jr. are currently about 25000 light-years apart (That’s 600 BILLION times the
            distance between Planet Earth, the cradle of civilization, and our capital Lunar City!) and will
            eventually move closer together to form a single supermassive black hole. Sprinkles is currently about
            two times the mass of Sprinkles Jr.!
            </p>
            
            So what are you waiting for? Book a tour now and see 3C 75 for yourself!
            </p>
          </div>
        </Fade>

        <div className='profile-pic-wrapper'>
          <Fade direction='right'>
            <img src={bhpic} className='profile-pic' alt="Profile"/>
          </Fade>
        </div>
        
      </div>
    </div>
  );
}

export default About3C75;