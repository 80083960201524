import './Projects.css';
// import {GitHubButton, Icon} from '../components/Button';
import ProjectCard from '../components/ProjectCard';
// import arduinoicon from '../images/arduino.png';
// import jslogo from '../images/javascript.png';
// import reactlogo from '../images/react.png';
// import htmlicon from '../images/html.png';
// import cssicon from '../images/css.png';
// import website from '../images/website_screenshot.jpg';
// import lightproj from '../images/light_project.jpg';
// import question from '../images/question-marks.jpg';
import { Slide } from 'react-awesome-reveal';

function Projects(props) {
  return (
    <div id='projects' className='projects-wrapper'>
      <div className='projects'>
        <h1 className='projects-title'>Facts about 3C 75</h1>
        <p></p>
        <div className='project-cards'>
          
          <Slide direction="right" cascade="true">

            <ProjectCard
              title="Mass"
              desc="Sprinkles: Up to 2 x 10e11 solar masses; Sprinkles Jr.: Up to 10e11 solar masses."
            />

            <ProjectCard 
              title="Orbital period"
              desc="10e8 years"
            />

            <ProjectCard
              title="Schwarzschild radii"
              desc="Sprinkles: 5.929 x 10e14 m; Sprinkles Jr.: 2.964 x 10e14 m"
            />

            <ProjectCard
              title="Eddington limit"
              desc="Sprinkles: 1.225 x 10e42 W; Sprinkles Jr.: 2.450 x 10e42 W"
            />

            <ProjectCard
              title="Temperature (of the accretion disks)"
              desc="Sprinkles: 9.28 x 10e6 K; Sprinkles Jr.: 8.12 x 10e6 K"
            />

            <ProjectCard
              title="Distance between the two / Time until collision"
              desc="25000 light years / Approximately 290 million years"
            />

            <ProjectCard
              title="Gravitational waves!"
              desc="Currently, Sprinkles and Sprinkles Jr. produce small gravitational waves through the fabric of
              space-time all the time as they spin round and round. Don’t worry, gravitational waves only compress
              and stretch space-time by a small amount, usually by a distance the fraction of an atomic nucleus,
              so these will not affect you. Eventually, when these blackholes collide, they will create some of
              the biggest gravitational waves we have ever observed! Rush to book your tickets because while it
              will be around another 300 million years before the gravitational waves from this event reach Earth,
              the actual collision will likely happen soon if you want to catch it up close. (Never too early to
              book your tickets for your next visit to BRB attractions though, so book now!) "
            />

            <ProjectCard
              title="Jets!"
              desc="We cannot talk about our stars (metaphorically not literally) Sprinkles and Sprinkles Jr.
              without discussing their MASSIVE JETS! They each shoot out two jets. How do they create these
              jets? Their massive size means they each have a really strong gravitational field that captures
              a huge amount of material from around them. That material then spins around the black holes really
              fast and creates a magnetic field. The magnetic field is powerful enough to launch ionized particles
              across the length of its home galaxy and into the Universe! To see the jets, visit our nearby radio
              telescopes on Planet Electra-4269 and Planet Chelsea-42. Alternatively, book a ticket on one of
              our hyper-tour buses that passes by 3C 75 at a safe distance equipped with radio-wave detector glasses."
            />

            <ProjectCard
              title="Gas!"
              desc="Our 3C 75 X-ray observatories are less popular (so less crowded and more reasonably priced tickets)
              but they are a great place to see the hot gas clouds or intracluster medium surrounding 3C 75. At nearly
              10 million Kelvin, this medium consists of mainly ionized hydrogen and helium and emits mostly X-rays."
            />

            {/* <ProjectCard
              title="Personal Website"
              date="2021"
              image={website}
              img_desc="website"
              desc="This website is my first foray into web development. I created this website
              using React, all while making sure it displays nicely on both mobile screens
              and large displays. Animations were implemented using React Reveal, Material UI,
              and CSS."
              buttons={
                <div className='project-card-buttons'>
                  <Icon icon={htmlicon} alt="HTML" />
                  <Icon icon={cssicon} alt="CSS" />
                  <Icon icon={jslogo} alt="Javascript" />
                  <Icon icon={reactlogo} alt="React.js" />
                  <GitHubButton link="https://github.com/phongulus/personal-website" />
                </div>
              }
            />

            <ProjectCard
              title="Mystery Project"
              date="Coming Soon!"
              image={question}
              img_desc="Question Marks"
              desc="Stay tuned for more fun projects that I'll undertake in the future!"
            /> */}
          </Slide>
        </div>
      </div>
    </div>
  )
}

export default Projects;