import * as React from 'react';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import './FAQ.css';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid rgba(4, 28, 50)`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem', color: 'rgba(202, 202, 202)' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(5, 45, 63)',
  color: 'rgba(202, 202, 202)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'rgba(5, 54, 77)',
  textAlign: 'left',
  color: 'rgba(202, 202, 202)',
  borderTop: '1px solid rgba(4, 28, 50)',
}));

export default function CustomizedAccordions() {
  const [expanded, setExpanded] = React.useState('panel1');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div id='faq' className='faq-wrapper'>
      <div className='faq'>
        <h1 className='faq-title'>Frequently Asked Questions</h1>
        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
          <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
            <Typography>Can we visit the singularity of a blackhole?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Throughout our many years in operation, many of our guests have asked our staff about this.
              The answer? NO. We do not conduct tours to the singularity as once anything passes the event horizon,
              it will likely be spaghettified and never return. If you’re not convinced that being stretched out into
              a noodle-like shape is a terrible thing, we have spaghettification demonstrations you can watch at our
              various BRB museums. 

              If you are one of our more persistent guests, we can answer yes but you have to sign an agreement
              waiving BRB from all responsibility for your unsatisfactory experience. 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
          <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
            <Typography>Will I age slower when visiting attractions near 3C 75 due to time dilation?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
              Yes. While all our observatories are located at a safe distance from our blackholes but still experience
              some form of time dilation. This means you will be a little younger than your friends when you return
              from your trip. (Did you know that this used to be a huge issue back when we have yet to discover
              immortality?)

              If you plan to visit our 3C 75 observatory that experiences the greatest time dilation, please get your
              affairs in order for the next 10 years before visiting.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
          <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
            <Typography>Is Hawking Radiation dangerous?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Hawking radiation is not dangerous. Hawking radiation occurs when a particle and antiparticle
            spawn into existence near a blackhole and the antiparticle enters the blackhole while the particle
            escapes. The rate at which particles come into existence and escape is nowhere near high enough to
            pose a risk.

            However, it does mean the black holes will only be here for a limited time (as it takes a long time
            for a blackhole as big as the Sprinkles to radiate away into the universe while
            occasionally accreting additional mass), so book your tour before the heat death of the universe! 
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
          <AccordionSummary aria-controls="panel4d-content" id="panel4d-header">
            <Typography>Is there wildlife around blackholes?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            Apart from a few adventurous park-goers, no, so we no longer conduct blackhole safaris.
            The strong gravity of 3C 75 often creates conditions on nearby planets that are hostile
            to life.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
          <AccordionSummary aria-controls="panel5d-content" id="panel5d-header">
            <Typography>What does my ticket pay for?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>
            All proceeds from tickets sold by BRB go towards staffing costs, maintaining park facilities,
            building new park facilities, further research into blackholes, and further conquest of the Universe!
            That's right, your money will go toward expanding the sphere of influence of our glorious Empire, so
            there's nothing more patriotic and family-friendly than a BRB tour!
            Please note that tickets only cover for some accidents specified under the standard BRB Insurance
            Policy. If you wish to engage in riskier park activities, please consider buying private insurance.
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    </div>
  );
}
