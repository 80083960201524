import './Diagram.css';
import bhpic from '../images/bh-diagram.png';
import { Fade } from 'react-awesome-reveal';
// import { ResumeButton } from '../components/Button';

function Diagram(props) {
  return (
      <div className='diagram-wrapper' id='diagram'>
        <div className='diagram'>
          <h1 className='diagram-title'>3C 75 Up Close</h1>
          <p>
            The black holes making up 3C 75 are supermassive black holes. In case you've forgotten what
            these look like, please find a BRB-standard supermassive black hole diagram below! (Please
            note that this is for illustrative purposes only and is not to scale. Only one of the two
            black holes are represented.)
          </p>
          <Fade>
            <div className='diagram-pic-wrapper'>
              <img src={bhpic} alt="Black hole" className='diagram-pic'/>
              {/* <Fade direction='right'>
                <img src={bhpic} alt="Black hole" className='diagram-pic'/>
              </Fade> */}
            </div>
          </Fade>
        </div>
      </div>
  );
}

export default Diagram;