import './Home.css';
import { Zoom } from 'react-awesome-reveal';
import scroll from '../images/scroll.png';

function Home(props) {
  return (
    <div id='home' className='home'>
      <Zoom>
        <h1 className='hello'>3C 75</h1>
      </Zoom>
      <Zoom delay="50">
        <h2 className='name'>Tour Guide and Travel Advisory</h2>
      </Zoom>
      <Zoom delay="60">
        <h5>(scroll down to find out more!)</h5>
      </Zoom>
      <Zoom delay="60">
        <img src={scroll} className='scroll-icon' alt='scroll'/>
      </Zoom>
    </div>
  );
}

export default Home;